import { $, $$ } from '../helpers/qsa'

const faqs = $$('.faqs__item__question');

[].forEach.call(faqs, function(faq) {
    faq.addEventListener("click", function(e){
        e.preventDefault();
        faq.classList.toggle('active')
    })
});









