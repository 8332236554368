import { $, $$ } from '../helpers/qsa'


let firstRun = true;
let mql = window.matchMedia('(max-width: 1000px)');
let mobileNav = mql.matches


function screenTest(e) {
    if (e.matches) {
        mobileNav = true

        if(firstRun & mobileNav) {
            $('.masthead__nav-toggle').addEventListener('click', function(e){
                if(!mobileNav) return;
                e.preventDefault();
                if($('html').classList.contains('active-categories')) {
                    $('html').classList.remove('active-categories')
                }
                $('html').classList.toggle('active-nav')

                if($('.nav--categories li.active')) {
                    $('.nav--categories li.active').classList.remove('active')
                }
            })

            $('.masthead__nav__categories').addEventListener('click', function(e){
                if(!mobileNav) return;
                e.preventDefault();
                $('html').classList.add('active-categories')
            })

            $('.masthead__categories__nav > ul > li:first-child > a').addEventListener('click', function(e){
                if(!mobileNav) return;
                e.preventDefault();
                $('html').classList.remove('active-categories')
            })

            firstRun = false;
        }
    } else {

        mobileNav = false
        $('html').classList.remove('active-nav')
        $('html').classList.remove('active-categories')
    }
}

mql.addListener(screenTest);

screenTest(mql);







