import { $, $$ } from '../helpers/qsa'

if($('.sort-by__cta')) {
    $('.sort-by__cta').addEventListener('click', function() {
        $('.sort-by__cta').classList.toggle('active')
    })
}










