import { $, $$ } from './../helpers/qsa'
import Siema from 'siema'

$$('.siema').forEach(el => {
    const carousel = new Siema({
        el: el,
        loop: true,
        duration: 350,
        draggable: window.matchMedia('(max-width: 1000px)').matches
    })

    const autoSlide = setInterval(() => carousel.next(), 4000)

    $$('.siema-btn--prev', el).forEach(btn => {
        btn.addEventListener('click', () => {
            clearInterval(autoSlide)
            carousel.prev()
        })
    })

    $$('.siema-btn--next', el).forEach(btn => {
        btn.addEventListener('click', () => {
            clearInterval(autoSlide)
            carousel.next()
        })
    })

    el.addEventListener('touchstart', () => {
        clearInterval(autoSlide)
    })
})
